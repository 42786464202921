import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { useEventsHome } from '../../hooks/getEventsHomepage';

const Events = () => {
    const eventsData = useEventsHome();
    return (
        <section className="page-section smallwdt" id="homeeventswrap">
            <div className="container">
                <h2 className="bluetxt">
                    UPCOMING EVENTS{' '}
                    <Link to="/events">VIEW ALL</Link>
                </h2>
                <div className="homethreeev">
                    {eventsData.map(event => (
                        <div className="homeeventbox" key={event.id}>
                            <Img
                                fluid={
                                    event.featured_media.localFile
                                        .childImageSharp.fluid
                                }
                            />
                            <h5 className="bluetxt">
                                <Link
                                    to={`/${event.slug}`}
                                    dangerouslySetInnerHTML={{
                                        __html: event.title,
                                    }}
                                />
                            </h5>
             {/*               <p
                                className="homeevdate"
                                dangerouslySetInnerHTML={{
                                    __html: event.acf.event_date,
                                }}
                            ></p>  */}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
export default Events;
